<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "containers.add-role-to-container.add-role-to-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('containers', 'add-role-to-container', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="roles && roles.length">
      <li class="clebex-item-section-item" v-for="role in roles" :key="role.id">
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedRole(role)"
        >
          <span class="label">
            <span class="highlight">{{ role.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                selectedRoles &&
                  selectedRoles.filter(item => item.id === role.id).length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "ContainersAddRole",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  mixins: [paginationMixin],
  created() {
    this.fetchRoles({
      access_type_ids: [this.containerTypeId]
    });
  },
  computed: {
    ...mapState("containers", [
      "listOfRoles",
      "selectedRoles",
      "rolesInContainer",
      "containerAccessTypes"
    ]),
    containerTypeId() {
      const { containerAccessTypes, containerType } = this;
      if (
        containerType &&
        containerAccessTypes &&
        containerAccessTypes.data &&
        containerAccessTypes.data.length
      ) {
        const filtered = containerAccessTypes.data.filter(
          item => item.type === containerType
        );
        if (filtered && filtered.length) {
          return filtered[0].id;
        }
        return null;
      }
      return null;
    },
    roles() {
      const query = this.searchQuery;
      if (this.listOfRoles && this.listOfRoles.length) {
        if (query && query.length > 1) {
          return this.listOfRoles.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.listOfRoles;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("containers", [
      "getRolesList",
      "setSelectedRole",
      "getContainerAccessTypes"
    ]),
    fetchRoles() {
      const { containerTypeId } = this;
      if (containerTypeId) {
        this.getRolesList({
          access_type_ids: [containerTypeId]
        });
      } else {
        this.getContainerAccessTypes();
      }
    }
  },
  watch: {
    containerTypeId() {
      this.fetchRoles({
        access_type_ids: [this.containerTypeId]
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    containerType: {
      required: true,
      type: String
    }
  }
};
</script>
